<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-datepicker
            :range="true"
            defaultStart="0d"
            defaultEnd="6d"
            label="출하 기간"
            name="dts"
            v-model="searchParam.dts"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-12">
        <c-table
          ref="gridShip"
          title="출하 현황"
          :columns="gridShip.columns"
          :data="gridShip.data"
          :filtering="false"
          :columnSetting="false"
          :editable="editable"
          selection="single"
          rowKey="mdmShippingId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addShip" v-if="editable" />
              <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeShip" v-if="editable" />
              <c-btn label="LBLSAVE" icon="save" @btnClicked="saveShip" v-if="editable" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getShipList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'ship',
  data() {
    return {
      searchParam: {
        plantCd: null,
        dts: [],
      },
      gridShip: {
        columns: [],
        data: [],
      },
      editable: true,
      listShipUrl: '',
      saveShipUrl: '',
      deleteShipUrl: '',
      isSaveShip: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listShipUrl = selectConfig.mdm.shipping.list.url
      this.saveShipUrl = transactionConfig.mdm.shipping.save.url
      this.deleteShipUrl = transactionConfig.mdm.shipping.save.url

      this.$comm.getComboItems('SHIPPING_TYPE_CD').then(__result1 => {
        this.gridShip.columns = [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:160px',
            type: 'plant',
          },
          {
            name: 'workDt',
            field: 'workDt',
            label: '출하일자',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:200px',
            type: 'date',
          },
          {
            name: 'mdmShippingTypeCd',
            field: 'mdmShippingTypeCd',
            label: '출하구분',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:200px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: __result1,
          },
          {
            name: 'ownerName',
            field: 'ownerName',
            label: '화주사',
            align: 'left',
            sortable: true,
            required: true,
            type: 'text',
          },
          {
            name: 'workQty',
            field: 'workQty',
            label: '작업량',
            align: 'right',
            style: 'width:150px',
            sortable: true,
            required: true,
            type: 'number',
          },
          // {
          //   name: 'location',
          //   field: 'location',
          //   label: '위치',
          //   align: 'left',
          //   style: 'width:300px',
          //   sortable: true,
          //   required: true,
          //   type: 'text',
          // },
          {
            name: 'daynight',
            field: 'daynight',
            label: '주야구분',
            align: 'center',
            style: 'width:120px',
            sortable: true,
            required: true,
            type: 'text',
          },
        ]
        this.getShipList();
      });
    },
    getShipList() {
      this.$http.url = this.listShipUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridShip.data = _result.data;
      },);
    },
    addShip() {
      this.gridShip.data.splice(0, 0, {
        plantCd: this.searchParam.plantCd,  // 사업장
        workDt: '',
        mdmShippingId: uid(),  // 출하일련번호
        mdmShippingTypeCd: null,  // 출하구분
        ownerName: '',  // 화주사
        workQty: '',  // 작업량
        location: '',  // 위치
        daynight: '',  // 주야구분
        editFlag: 'C'
      })
    },
    saveShip() {
      if (this.$comm.validTable(this.gridShip.columns, this.gridShip.data)) {
        let saveData = this.gridShip.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId
          item.chgUserId = this.$store.getters.user.userId
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveShipUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
                this.getShipList();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeShip() {
      let selectData = this.$refs['gridShip'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteShipUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getShipList();
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
